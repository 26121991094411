/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AuthForgotPasswordRequest
 */
export interface AuthForgotPasswordRequest {
    /**
     *
     * @type {string}
     * @memberof AuthForgotPasswordRequest
     */
    email: string;
}

/**
 * Check if a given object implements the AuthForgotPasswordRequest interface.
 */
export function instanceOfAuthForgotPasswordRequest(value: object): value is AuthForgotPasswordRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function AuthForgotPasswordRequestFromJSON(json: any): AuthForgotPasswordRequest {
    return AuthForgotPasswordRequestFromJSONTyped(json, false);
}

export function AuthForgotPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthForgotPasswordRequest {
    if (json == null) {
        return json;
    }
    return {

        'email': json['email'],
    };
}

export function AuthForgotPasswordRequestToJSON(value?: AuthForgotPasswordRequest | null): any {
    if (value == null) {
        return value;
    }
    return {

        'email': value['email'],
    };
}


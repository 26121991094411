import React, {createContext, useContext, useEffect} from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

type ColorMode = 'light' | 'dark';

interface ColorModeContextType {
    colorMode: ColorMode;
    setColorMode: (mode: ColorMode) => void;
}

const ColorModeContext = createContext<ColorModeContextType | undefined>(undefined);

export const useColorMode = () => {
    const context = useContext(ColorModeContext);
    if (context === undefined) {
        throw new Error('useColorMode must be used within a ColorModeProvider');
    }
    return context;
};

export const ColorModeProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [colorMode, setColorMode] = useLocalStorage<ColorMode>('color-theme', 'light');

    useEffect(() => {
        const className = 'dark';
        const bodyClass = window.document.body.classList;

        colorMode === 'dark' ? bodyClass.add(className) : bodyClass.remove(className);
    }, [colorMode]);

    return (
        <ColorModeContext.Provider value={{colorMode, setColorMode}}>
            {children}
        </ColorModeContext.Provider>
    );
};
import React, {useState} from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';


interface PopoverProps {
    trigger: React.ReactNode;
    title: string;
    content: React.ReactNode;
    position?: 'top' | 'right' | 'bottom' | 'left';
}

const Popover: React.FC<PopoverProps> = ({trigger, title, content, position = 'bottom'}) => {
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useOutsideClick(() => setIsOpen(false));

    const getPositionClasses = () => {
        switch (position) {
            case 'top':
                return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
            case 'right':
                return 'left-full top-1/2 transform -translate-y-1/2 ml-2';
            case 'left':
                return 'right-full top-1/2 transform -translate-y-1/2 mr-2';
            default:
                return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
        }
    };

    return (
        <div className="relative inline-block">
            <div onClick={() => setIsOpen(!isOpen)}>{trigger}</div>

            {isOpen &&
                <div
                    ref={popoverRef}
                    className={`absolute z-20 mt-3 w-max max-w-[400px] rounded bg-white drop-shadow-5 dark:bg-meta-4 ${getPositionClasses()}`}
                >

                    <div className="border-b border-stroke p-3 dark:border-strokedark">
                        <h4 className="text-center text-title-sm font-semibold text-black dark:text-white">
                            {title}
                        </h4>
                    </div>
                    <div className="px-5 py-4.5 text-left">
                        <p className="font-medium whitespace-pre-line">
                            {content}
                        </p>
                    </div>
                </div>
            }
        </div>
    );
};

export default Popover;
import {ChevronLeft, ChevronRight, X} from 'lucide-react';
import React, {useCallback, useEffect, useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface PropertyImageGalleryProps {
    images: string[];
}

const PropertyImageGallery: React.FC<PropertyImageGalleryProps> = ({images}) => {
    const [fullscreenIndex, setFullscreenIndex] = useState<number | null>(null);

    const handleImageClick = (index: number) => {
        setFullscreenIndex(index);
    };

    const closeFullscreen = useCallback(() => {
        setFullscreenIndex(null);
    }, []);

    const navigateFullscreen = (direction: 'prev' | 'next') => {
        if (fullscreenIndex === null) return;
        const newIndex = direction === 'prev'
            ? (fullscreenIndex - 1 + images.length) % images.length
            : (fullscreenIndex + 1) % images.length;
        setFullscreenIndex(newIndex);
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (fullscreenIndex !== null) {
                if (event.key === 'Escape') {
                    closeFullscreen();
                } else if (event.key === 'ArrowLeft') {
                    navigateFullscreen('prev');
                } else if (event.key === 'ArrowRight') {
                    navigateFullscreen('next');
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [fullscreenIndex, closeFullscreen]);

    return (
        <div className="relative mb-5">
            <div className="grid grid-cols-4 gap-2 h-[400px]">
                {/* Primary Image */}
                <div
                    className="col-span-2 row-span-2 cursor-pointer overflow-hidden rounded-lg"
                    onClick={() => handleImageClick(0)}
                >
                    <LazyLoadImage
                        src={images[0]}
                        alt="Primary property image"
                        effect="blur"
                        className="h-full w-full object-cover"
                    />
                </div>

                {/* Secondary Images */}
                {images.slice(1, 5).map((image, index) => (
                    <div
                        key={index}
                        className="cursor-pointer overflow-hidden rounded-lg"
                        onClick={() => handleImageClick(index + 1)}
                    >
                        <LazyLoadImage
                            src={image}
                            alt={`Property image ${index + 2}`}
                            effect="blur"
                            className="h-full w-full object-cover"
                        />
                    </div>
                ))}
            </div>
            {/* Fullscreen Gallery */}
            {fullscreenIndex !== null && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-90 z-99999 flex items-center justify-center"
                    onClick={closeFullscreen}
                >
                    <div
                        className="relative w-full h-full flex items-center justify-center"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={closeFullscreen}
                            className="absolute top-4 right-4 text-white p-2 rounded-full hover:bg-white hover:bg-opacity-20 z-99999"
                        >
                            <X size={24}/>
                        </button>
                        <div className="flex items-center justify-between w-full px-4">
                            <button
                                onClick={() => navigateFullscreen('prev')}
                                className="text-white p-2 rounded-full hover:bg-white hover:bg-opacity-20 z-99999"
                            >
                                <ChevronLeft size={40}/>
                            </button>
                            <div className="flex-grow flex justify-center items-center">
                                <LazyLoadImage
                                    src={images[fullscreenIndex]}
                                    alt={`Full screen property image ${fullscreenIndex + 1}`}
                                    effect="blur"
                                    className="max-h-[90vh] max-w-[80vw] object-contain"
                                />
                            </div>
                            <button
                                onClick={() => navigateFullscreen('next')}
                                className="text-white p-2 rounded-full hover:bg-white hover:bg-opacity-20 z-99999"
                            >
                                <ChevronRight size={40}/>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PropertyImageGallery;
/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AuthRefreshRequest
 */
export interface AuthRefreshRequest {
    /**
     *
     * @type {string}
     * @memberof AuthRefreshRequest
     */
    refreshToken: string;
}

/**
 * Check if a given object implements the AuthRefreshRequest interface.
 */
export function instanceOfAuthRefreshRequest(value: object): value is AuthRefreshRequest {
    if (!('refreshToken' in value) || value['refreshToken'] === undefined) return false;
    return true;
}

export function AuthRefreshRequestFromJSON(json: any): AuthRefreshRequest {
    return AuthRefreshRequestFromJSONTyped(json, false);
}

export function AuthRefreshRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthRefreshRequest {
    if (json == null) {
        return json;
    }
    return {

        'refreshToken': json['refresh_token'],
    };
}

export function AuthRefreshRequestToJSON(value?: AuthRefreshRequest | null): any {
    if (value == null) {
        return value;
    }
    return {

        'refresh_token': value['refreshToken'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {PaginatedPropertiesResponse, Property, PropertyPatchRequest, PropertyResponse,} from '../models/index';
import {
    PaginatedPropertiesResponseFromJSON,
    PropertyFromJSON,
    PropertyPatchRequestToJSON,
    PropertyResponseFromJSON,
} from '../models/index';

export interface PropertiesRequest {
    limit?: number;
    offset?: number;
    minBeds?: number;
    minBaths?: number;
    maxBeds?: number;
    maxBaths?: number;
    minProfit?: number;
    maxProfit?: number;
    stateCode?: string;
    city?: string;
    propertyType?: PropertiesPropertyTypeEnum;
}

export interface PropertyRequest {
    id: string;
}

export interface PropertyUpdateRequest {
    id: string;
    propertyPatchRequest: PropertyPatchRequest;
}

/**
 *
 */
export class PropertiesApi extends runtime.BaseAPI {

    /**
     * Logs user into the system
     * Logs user into the system
     */
    async propertiesRaw(requestParameters: PropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPropertiesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['minBeds'] != null) {
            queryParameters['min_beds'] = requestParameters['minBeds'];
        }

        if (requestParameters['minBaths'] != null) {
            queryParameters['min_baths'] = requestParameters['minBaths'];
        }

        if (requestParameters['maxBeds'] != null) {
            queryParameters['max_beds'] = requestParameters['maxBeds'];
        }

        if (requestParameters['maxBaths'] != null) {
            queryParameters['max_baths'] = requestParameters['maxBaths'];
        }

        if (requestParameters['minProfit'] != null) {
            queryParameters['min_profit'] = requestParameters['minProfit'];
        }

        if (requestParameters['maxProfit'] != null) {
            queryParameters['max_profit'] = requestParameters['maxProfit'];
        }

        if (requestParameters['stateCode'] != null) {
            queryParameters['state_code'] = requestParameters['stateCode'];
        }

        if (requestParameters['city'] != null) {
            queryParameters['city'] = requestParameters['city'];
        }

        if (requestParameters['propertyType'] != null) {
            queryParameters['property_type'] = requestParameters['propertyType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPropertiesResponseFromJSON(jsonValue));
    }

    /**
     * Logs user into the system
     * Logs user into the system
     */
    async properties(requestParameters: PropertiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPropertiesResponse> {
        const response = await this.propertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a property detail
     * Gets a property detail
     */
    async propertyRaw(requestParameters: PropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PropertyResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling property().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyResponseFromJSON(jsonValue));
    }

    /**
     * Gets a property detail
     * Gets a property detail
     */
    async property(requestParameters: PropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PropertyResponse> {
        const response = await this.propertyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a property
     * Updates a property
     */
    async propertyUpdateRaw(requestParameters: PropertyUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Property>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling propertyUpdate().'
            );
        }

        if (requestParameters['propertyPatchRequest'] == null) {
            throw new runtime.RequiredError(
                'propertyPatchRequest',
                'Required parameter "propertyPatchRequest" was null or undefined when calling propertyUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyPatchRequestToJSON(requestParameters['propertyPatchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyFromJSON(jsonValue));
    }

    /**
     * Updates a property
     * Updates a property
     */
    async propertyUpdate(requestParameters: PropertyUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Property> {
        const response = await this.propertyUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const PropertiesPropertyTypeEnum = {
    House: 'House',
    Apartment: 'Apartment',
    Townhouse: 'Townhouse',
    Condo: 'Condo'
} as const;
export type PropertiesPropertyTypeEnum = typeof PropertiesPropertyTypeEnum[keyof typeof PropertiesPropertyTypeEnum];

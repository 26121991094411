import React, { createContext, useContext, useEffect, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserMe } from '../clients/vercasa';
import { useAuthAPI, useUserAPI } from '../hooks/useVerCasaAPI';
import { useApi } from './ApiContext';

interface AuthContextType {
    user: UserMe | null;
    login: (email: string, password: string) => Promise<void>;
    signup: (email: string, password: string) => Promise<void>;
    logout: () => void;
    setUser: (user: UserMe | null) => void;
    isInitialized: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState<UserMe | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const { tokens, setTokens } = useApi();
    const { loginUser, refreshAuth } = useAuthAPI();
    const { me, signupUser } = useUserAPI();

    // Use ref to track actual initialization state
    const initializationInProgress = useRef(false);

    // Single initialization function
    const initializeAuth = useCallback(async () => {
        // Prevent concurrent initializations
        if (initializationInProgress.current || isInitialized) return;

        initializationInProgress.current = true;

        try {
            let currentUser: UserMe | null = null;

            try {
                currentUser = await me();
            } catch (error) {
                // Only try refresh if we have tokens
                if (tokens?.refreshToken) {
                    await refreshAuth(tokens.refreshToken);
                    currentUser = await me();
                }
            }

            setUser(currentUser);
        } catch (error) {
            setTokens(null);
            setUser(null);
        } finally {
            initializationInProgress.current = false;
            setIsInitialized(true);
        }
    }, [tokens, me, refreshAuth, setTokens]);

    const login = useCallback(async (email: string, password: string) => {
        setIsInitialized(false);
        const authTokens = await loginUser(email, password);
        setTokens(authTokens);
    }, [loginUser, setTokens]);

    const signup = useCallback(async (email: string, password: string) => {
        setIsInitialized(false);
        const authTokens = await signupUser(email, password);
        setTokens(authTokens);
    }, [signupUser, setTokens]);

    const logout = useCallback(() => {
        setTokens(null);
        setUser(null);
        navigate('/auth/signin');
    }, [setTokens]);

    // Single effect for initialization
    useEffect(() => {
        initializeAuth();
    }, [initializeAuth]);

    return (
        <AuthContext.Provider value={{
            user,
            setUser,
            login,
            signup,
            logout,
            isInitialized
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
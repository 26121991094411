import {ChevronDown} from 'lucide-react';
import React from 'react';


interface SelectProps {
    label: string;
    options: { label: string; value: any }[];
    selected: string;

    setSelected(value: string): void;
}


const SelectGroupThree: React.FC<SelectProps> = ({label, options, selected, setSelected}) => {
    return (
        <div className="mb-5.5">
            {label && (
                <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    {label}
                </label>
            )}

            <div className="relative z-20 bg-transparent dark:bg-form-input">
                <select
                    value={selected}
                    onChange={(e) => {
                        setSelected(e.target.value);
                    }}
                    className="relative z-20 w-full appearance-none bg-transparent outline-none rounded border bg-transparent py-3 px-5 transition border border-primary focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                    {options.map((option) => (
                        <option
                            key={option.value}
                            value={option.value}
                            className="text-body dark:text-bodydark"
                        >
                            {option.label}
                        </option>
                    ))}
                </select>

                <span className="absolute right-4 top-1/2 z-30 -translate-y-1/2">
          <ChevronDown/>
        </span>
            </div>
        </div>
    );
};

export default SelectGroupThree;

import * as Sentry from "@sentry/react";
import { Route } from 'react-router-dom';
import Loader from './common/Loader';
import { ApiProvider } from './contexts/ApiContext';
import { useLoading } from './contexts/LoadingContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ColorModeProvider } from './contexts/ColorModeContext';
import { LoadingProvider } from './contexts/LoadingContext';
import ForgotPassword from './pages/Authentication/ForgotPassword.tsx';
import SignIn from './pages/Authentication/SignIn.tsx';
import SignUp from './pages/Authentication/SignUp.tsx';
import ECommerce from './pages/Dashboard/ECommerce';
import ErrorPage from './pages/ErrorPage';
import PropertiesSearch from './pages/Property/PropertiesSearch.tsx';
import PropertyDetail from './pages/Property/PropertyDetail.tsx';
import PropertyLists from './pages/PropertyList/PropertyLists.tsx';
import Settings from './pages/User/Settings.tsx';
import ProtectedRoute from './routes/ProtectedRoutes';
import PublicRoute from './routes/PublicRoutes';
import { SentryRoutes } from './sentry';
import ResetPassword from "./pages/Authentication/ResetPassword.tsx";


function App() {
    return (
        <LoadingProvider>
            <ColorModeProvider>
                <ApiProvider>
                    <AuthProvider>
                        <AppRouter />
                    </AuthProvider>
                </ApiProvider>
            </ColorModeProvider>
        </LoadingProvider>
    );
}

const AppRouter: React.FC = () => {
    const { isInitialized, user } = useAuth();
    const { isLoading } = useLoading();
    const showLoader = isLoading || (!isInitialized && user !== null);

    if (!isInitialized) {
        return <Loader />;
    }

    return (
        <>
            {showLoader && <Loader />}
            <SentryRoutes>
                {/* Public routes */}
                <Route element={<PublicRoute />}>
                    <Route path="/auth/signin" element={<SignIn />} />
                    <Route path="/auth/signup" element={<SignUp />} />
                    <Route path="/auth/forgot-password" element={<ForgotPassword />} />
                    <Route path="/auth/reset-password" element={<ResetPassword />} />
                </Route>

                {/* Protected routes */}
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<ECommerce />} />
                    <Route path="/properties" element={<PropertiesSearch />} />
                    <Route path="/properties/:id" element={<PropertyDetail />} />
                    <Route path="/user/settings" element={<Settings />} />
                    <Route path="/property-lists" element={<PropertyLists />} />
                    {/* Add your other protected routes here */}
                </Route>

                {/* Fallback route for 404 */}
                <Route path="*" element={<ErrorPage />} />
            </SentryRoutes>
        </>
    );
};

export default Sentry.withProfiler(App);
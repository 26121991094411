import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';

const PublicRoute: React.FC = () => {
    const {user} = useAuth();

    if (user) {
        return <Navigate to="/" replace/>;
    }

    return <Outlet/>;
};

export default PublicRoute;
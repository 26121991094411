/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AuthResetPasswordRequest
 */
export interface AuthResetPasswordRequest {
    /**
     *
     * @type {string}
     * @memberof AuthResetPasswordRequest
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof AuthResetPasswordRequest
     */
    token: string;
    /**
     *
     * @type {string}
     * @memberof AuthResetPasswordRequest
     */
    newPassword: string;
}

/**
 * Check if a given object implements the AuthResetPasswordRequest interface.
 */
export function instanceOfAuthResetPasswordRequest(value: object): value is AuthResetPasswordRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('newPassword' in value) || value['newPassword'] === undefined) return false;
    return true;
}

export function AuthResetPasswordRequestFromJSON(json: any): AuthResetPasswordRequest {
    return AuthResetPasswordRequestFromJSONTyped(json, false);
}

export function AuthResetPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthResetPasswordRequest {
    if (json == null) {
        return json;
    }
    return {

        'email': json['email'],
        'token': json['token'],
        'newPassword': json['new_password'],
    };
}

export function AuthResetPasswordRequestToJSON(value?: AuthResetPasswordRequest | null): any {
    if (value == null) {
        return value;
    }
    return {

        'email': value['email'],
        'token': value['token'],
        'new_password': value['newPassword'],
    };
}


import { useCallback, useMemo } from "react";
import { AuthTokens, PropertiesRequest, PropertyListsRequest, UsersMeUpdateRequest } from "../clients/vercasa";
import { useApi } from "../contexts/ApiContext";
import { useLoading } from "../contexts/LoadingContext";

export const useApiHandler = () => {
    const { setLoading } = useLoading();

    const handleApiCall = async (apiCall: () => any) => {
        setLoading(true);
        try {
            return await apiCall();
        } catch (err) {
            console.error('API call error:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { handleApiCall }
};

export const useAuthAPI = () => {
    const { authApi, setTokens } = useApi();
    const { handleApiCall } = useApiHandler();

    const loginUser = useCallback(async (email: string, password: string) => {
        return await handleApiCall(async () => {
            const res: AuthTokens = await authApi.authLogin({ authLoginRequest: { email, password } })
            setTokens(res);
            return res;
        });
    }, [authApi]);

    const refreshAuth = useCallback(async (refreshToken: string) => {
        return await handleApiCall(async () => {
            const res: AuthTokens = await authApi.authRefresh({ authRefreshRequest: { refreshToken: refreshToken } })
            setTokens(res);
            return res;
        });
    }, [authApi]);


    const forgotPassword = useCallback(async (email: string) => {
        return await handleApiCall(async () => {
            return await authApi.authForgotPassword({ authForgotPasswordRequest: { email: email } });
        });
    }, [authApi]);

    const resetPassword = useCallback(async (email: string, password: string, token: string) => {
        return await handleApiCall(async () => {
            return await authApi.authResetPassword({
                authResetPasswordRequest: {
                    email: email,
                    token: token,
                    newPassword: password
                }
            })
        });
    }, [authApi]);

    return { loginUser, refreshAuth, forgotPassword, resetPassword }
}

export const useUserAPI = () => {
    const { usersApi, setTokens } = useApi();
    const { handleApiCall } = useApiHandler();

    const me = useCallback(async () => {
        return await handleApiCall(() => usersApi.usersMe());
    }, [usersApi]);

    const updateUser = useCallback(async (data: UsersMeUpdateRequest) => {
        return await handleApiCall(() => usersApi.usersMeUpdate(data));
    }, [usersApi]);

    const signupUser = useCallback(async (email: string, password: string) => {
        return await handleApiCall(async () => {
            const res: AuthTokens = await usersApi.userCreate({ userCreateRequest: { email, password } })
            setTokens(res);
            return me();
        });
    }, [usersApi]);

    return { me, updateUser, signupUser };
}


export const usePropertiesAPI = () => {
    const { propertiesApi } = useApi();
    const { handleApiCall } = useApiHandler();


    const getProperties = useCallback(async (params: PropertiesRequest) => {
        return await handleApiCall(() => propertiesApi.properties(params));
    }, [propertiesApi]);

    const getProperty = useCallback(async (id: string) => {
        return await handleApiCall(() => propertiesApi.property({ id: id! }));
    }, [propertiesApi]);

    const favoriteProperty = useCallback(async (id: string, favorite: boolean) => {
        return await handleApiCall(() => propertiesApi.propertyUpdate({ id, propertyPatchRequest: { favorite } }));
    }, [propertiesApi]);

    return { getProperties, getProperty, favoriteProperty };
};

export const useAddressAPI = () => {
    const { addressesApi } = useApi();
    const { handleApiCall } = useApiHandler();

    const getStates = useCallback(async () => {
        return await handleApiCall(() => addressesApi.states());
    }, [addressesApi]);

    const getCities = useCallback(async (stateCode: string) => {
        return await handleApiCall(() => addressesApi.cities({ stateCode }));
    }, [addressesApi]);

    return { getStates, getCities }
};


export const usePropertyListsAPI = () => {
    const { propertyListsApi } = useApi();
    const { handleApiCall } = useApiHandler();

    const getLists = useCallback(async (params: PropertyListsRequest) => {
        return await handleApiCall(() => propertyListsApi.propertyLists(params));
    }, [propertyListsApi]);

    const createList = useCallback(async (name: string) => {
        return await handleApiCall(() =>
            propertyListsApi.propertyListsCreate({ propertyListCreateRequest: { name } })
        );
    }, [propertyListsApi]);

    const deleteList = useCallback(async (id: string) => {
        return await handleApiCall(() => propertyListsApi.propertyListDelete({ id }));
    }, [propertyListsApi]);

    const addPropertyToList = useCallback(async (listId: string, propertyId: string) => {
        return await handleApiCall(() =>
            propertyListsApi.propertyListAddProperties({
                id: listId,
                propertyListAddPropertiesRequest: { propertyIds: [propertyId] }
            })
        );
    }, [propertyListsApi]);

    return { getLists, createList, deleteList, addPropertyToList }
}
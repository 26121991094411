import React, {ReactNode} from "react";
import useOutsideClick from "../../hooks/useOutsideClick";


interface ModalOneProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    title?: string;
}

const ModalOne: React.FC<ModalOneProps> = ({isOpen, onClose, children, title}) => {
    const modalRef = useOutsideClick(onClose);

    if (!isOpen) return null;

    return (

        <div
            className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 block">
            <div ref={modalRef}
                 className="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15">
                <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">{title}</h3>
                <span className="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>
                {children}
            </div>
        </div>

    );
};

export default ModalOne;
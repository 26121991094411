/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {Property} from './Property';
import {PropertyFromJSON, PropertyToJSON,} from './Property';

/**
 *
 * @export
 * @interface PaginatedPropertiesResponse
 */
export interface PaginatedPropertiesResponse {
    /**
     *
     * @type {Array<Property>}
     * @memberof PaginatedPropertiesResponse
     */
    data: Array<Property>;
    /**
     *
     * @type {number}
     * @memberof PaginatedPropertiesResponse
     */
    count: number;
    /**
     *
     * @type {number}
     * @memberof PaginatedPropertiesResponse
     */
    limit: number;
    /**
     *
     * @type {number}
     * @memberof PaginatedPropertiesResponse
     */
    offset: number;
}

/**
 * Check if a given object implements the PaginatedPropertiesResponse interface.
 */
export function instanceOfPaginatedPropertiesResponse(value: object): value is PaginatedPropertiesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('limit' in value) || value['limit'] === undefined) return false;
    if (!('offset' in value) || value['offset'] === undefined) return false;
    return true;
}

export function PaginatedPropertiesResponseFromJSON(json: any): PaginatedPropertiesResponse {
    return PaginatedPropertiesResponseFromJSONTyped(json, false);
}

export function PaginatedPropertiesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedPropertiesResponse {
    if (json == null) {
        return json;
    }
    return {

        'data': ((json['data'] as Array<any>).map(PropertyFromJSON)),
        'count': json['count'],
        'limit': json['limit'],
        'offset': json['offset'],
    };
}

export function PaginatedPropertiesResponseToJSON(value?: PaginatedPropertiesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {

        'data': ((value['data'] as Array<any>).map(PropertyToJSON)),
        'count': value['count'],
        'limit': value['limit'],
        'offset': value['offset'],
    };
}


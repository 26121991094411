/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {City, State,} from '../models/index';
import {CityFromJSON, StateFromJSON,} from '../models/index';

export interface CitiesRequest {
    stateCode: string;
}

/**
 *
 */
export class AddressesApi extends runtime.BaseAPI {

    /**
     * Gets all of the distinct cities in the system, should be passing state_code as a query param
     * Gets all of the cities for a given state code.
     */
    async citiesRaw(requestParameters: CitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<City>>> {
        if (requestParameters['stateCode'] == null) {
            throw new runtime.RequiredError(
                'stateCode',
                'Required parameter "stateCode" was null or undefined when calling cities().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['stateCode'] != null) {
            queryParameters['state_code'] = requestParameters['stateCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/addresses/cities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CityFromJSON));
    }

    /**
     * Gets all of the distinct cities in the system, should be passing state_code as a query param
     * Gets all of the cities for a given state code.
     */
    async cities(requestParameters: CitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<City>> {
        const response = await this.citiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * gets all distinct states in the system
     * gets all distinct states in the system
     */
    async statesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<State>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/addresses/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StateFromJSON));
    }

    /**
     * gets all distinct states in the system
     * gets all distinct states in the system
     */
    async states(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<State>> {
        const response = await this.statesRaw(initOverrides);
        return await response.value();
    }

}

import {FloorPlan, Property, RentalEstimate} from '../clients/vercasa';

export const getPropertyStats = (property: Property) => {
    const sortedFloorPlans = sortFloorPlansByProfit(property.floorPlans);
    const mostExpensivePrice = getMostExpensiveFloorPlanPrice(property.floorPlans);
    const mostProfitableFloorPlan = getMostProfitableFloorPlanFromSorted(sortedFloorPlans);
    const highestOverallProfit = getHighestProfitFromFloorPlan(mostProfitableFloorPlan);
    const highestOverallAverageDailyRate = getHighestAverageDailyRateFromFloorPlan(mostProfitableFloorPlan);

    return {
        sortedFloorPlans,
        mostExpensivePrice,
        highestOverallProfit,
        highestOverallAverageDailyRate
    };
};

export const getMostProfitableFloorPlan = (property: Property): FloorPlan => {
    return property.floorPlans.reduce((mostProfitable, currentPlan) => {
        const currentMaxProfit = Math.max(...currentPlan.rentalEstimates.map(re => re.profit));
        const mostProfitableMaxProfit = Math.max(...mostProfitable.rentalEstimates.map(re => re.profit));

        return currentMaxProfit > mostProfitableMaxProfit ? currentPlan : mostProfitable;
    });
};

export const getHighestProfitFromFloorPlan = (floorPlan: FloorPlan): number => {
    return Math.max(...floorPlan.rentalEstimates.map(re => re.profit));
};

export const getHighestAverageDailyRateFromFloorPlan = (floorPlan: FloorPlan): number => {
    return Math.max(...floorPlan.rentalEstimates.map(re => re.averageDailyRate));
};

export const getAverageOccupancyFromFloorPlan = (floorPlan: FloorPlan): number => {
    const occupancies = floorPlan.rentalEstimates.map(re => re.occupancy);
    return occupancies.reduce((sum, curr) => sum + curr, 0) / occupancies.length;
};

// New utility functions

export const sortFloorPlansByProfit = (floorPlans: FloorPlan[]): FloorPlan[] => {
    return [...floorPlans].sort((a, b) =>
        getHighestProfitFromFloorPlan(b) - getHighestProfitFromFloorPlan(a)
    );
};

export const getMostExpensiveFloorPlanPrice = (floorPlans: FloorPlan[]): number => {
    return Math.max(...floorPlans.map(fp => fp.listedPrice));
};

export const getMostProfitableFloorPlanFromSorted = (sortedFloorPlans: FloorPlan[]): FloorPlan => {
    return sortedFloorPlans[0];
};


export const getHighestRentalEstimate = (property: Property): number => {
    return Math.max(...property.floorPlans.flatMap(fp => fp.rentalEstimates.map(re => re.averageDailyRate)));
};

export const getAverageOccupancy = (property: Property): number => {
    const occupancies = property.floorPlans.flatMap(fp => fp.rentalEstimates.map(re => re.occupancy));
    return occupancies.reduce((sum, curr) => sum + curr, 0) / occupancies.length;
};

export const getHighestProfit = (property: Property): number => {
    return Math.max(...property.floorPlans.flatMap(fp => fp.rentalEstimates.map(re => re.profit)));
};

export const getFloorPlanRange = (property: Property, key: keyof FloorPlan): [number, number] => {
    const values = property.floorPlans.map(fp => fp[key]);
    return [Math.min(...values as number[]), Math.max(...values as number[])];
};

export const getProfitColor = (profit: number): string => {
    if (profit > 12000) return 'text-green-500';
    if (profit > 6000) return 'text-yellow-500';
    return 'text-red-500';
};

// Helper function to get all rental estimates from a property
const getAllRentalEstimates = (property: Property): RentalEstimate[] => {
    return property.floorPlans.flatMap(fp => fp.rentalEstimates);
};

// Generic function to get the highest value for a given key in rental estimates
const getHighestValueFromRentalEstimates = (property: Property, key: keyof RentalEstimate): number => {
    const estimates = getAllRentalEstimates(property);
    return Math.max(...estimates.map(re => re[key] as number));
};

export const getHighestAverageDailyRate = (property: Property): number => {
    return getHighestValueFromRentalEstimates(property, 'averageDailyRate');
};

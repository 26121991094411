/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PointOfInterest
 */
export interface PointOfInterest {
    /**
     *
     * @type {string}
     * @memberof PointOfInterest
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof PointOfInterest
     */
    type: PointOfInterestTypeEnum;
    /**
     *
     * @type {number}
     * @memberof PointOfInterest
     */
    distance: number;
    /**
     *
     * @type {number}
     * @memberof PointOfInterest
     */
    drive: number;
    /**
     *
     * @type {number}
     * @memberof PointOfInterest
     */
    walk: number;
}


/**
 * @export
 */
export const PointOfInterestTypeEnum = {
    Education: 'Education',
    Shopping: 'Shopping',
    Transportation: 'Transportation'
} as const;
export type PointOfInterestTypeEnum = typeof PointOfInterestTypeEnum[keyof typeof PointOfInterestTypeEnum];


/**
 * Check if a given object implements the PointOfInterest interface.
 */
export function instanceOfPointOfInterest(value: object): value is PointOfInterest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('distance' in value) || value['distance'] === undefined) return false;
    if (!('drive' in value) || value['drive'] === undefined) return false;
    if (!('walk' in value) || value['walk'] === undefined) return false;
    return true;
}

export function PointOfInterestFromJSON(json: any): PointOfInterest {
    return PointOfInterestFromJSONTyped(json, false);
}

export function PointOfInterestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointOfInterest {
    if (json == null) {
        return json;
    }
    return {

        'name': json['name'],
        'type': json['type'],
        'distance': json['distance'],
        'drive': json['drive'],
        'walk': json['walk'],
    };
}

export function PointOfInterestToJSON(value?: PointOfInterest | null): any {
    if (value == null) {
        return value;
    }
    return {

        'name': value['name'],
        'type': value['type'],
        'distance': value['distance'],
        'drive': value['drive'],
        'walk': value['walk'],
    };
}


import {
    ArrowDownWideNarrow,
    ArrowUpDown,
    ArrowUpNarrowWide,
    ChevronLeft,
    ChevronRight,
    ChevronsLeft,
    ChevronsRight,
    CirclePlus,
    Trash2
} from 'lucide-react';
import React, {useEffect, useMemo, useState} from 'react';
import {useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {PropertyList} from '../../clients/vercasa';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb.tsx';
import ModalOne from '../../components/Modals/ModalOne.tsx';
import DeleteConfirmModal from '../../components/Modals/ModalTwo.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import {usePropertyListsAPI} from '../../hooks/useVerCasaAPI.tsx';
import DefaultLayout from '../../layout/DefaultLayout.tsx';

const PropertyLists: React.FC = () => {
    const [propertyLists, setPropertyLists] = useState<PropertyList[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newListName, setNewListName] = useState('');
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [listToDelete, setListToDelete] = useState<PropertyList | null>(null);
    const {getLists, createList, deleteList} = usePropertyListsAPI();

    const [totalCount, setTotalCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const fetchPropertyLists = async (pageIndex: number, pageSize: number) => {
        try {
            setIsLoading(true);
            const response = await getLists({offset: pageIndex * pageSize, limit: pageSize});
            setPropertyLists(response.data);
            setTotalCount(response.count);
            setPageCount(Math.ceil(response.count / pageSize));
        } catch (error) {
            console.error('Error fetching property lists:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                width: '40%', // Largest column
            },
            {
                Header: 'Properties',
                accessor: 'propertyCount',
                Cell: ({value}) => `${value} Properties`,
                width: '20%',
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                Cell: ({value}) => new Date(value).toLocaleDateString(),
                width: '15%',
            },
            {
                Header: 'Updated At',
                accessor: 'updatedAt',
                Cell: ({value}) => new Date(value).toLocaleDateString(),
                width: '15%',
            },
            {
                Header: 'Actions',
                accessor: 'id',
                Cell: ({row}) => (
                    <button
                        className="hover:text-primary"
                        onClick={() => {
                            setListToDelete(row.original);
                            setDeleteModalOpen(true);
                        }}
                    >
                        <Trash2 className="h-5 w-5 hover:text-danger"/>
                    </button>
                ),
                width: '10%',
            },
        ],
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        setGlobalFilter,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        gotoPage,
    } = useTable(
        {
            columns,
            data: propertyLists,
            initialState: {pageSize: 10},
            manualPagination: true,
            pageCount,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {globalFilter, pageIndex, pageSize} = state;

    useEffect(() => {
        fetchPropertyLists(pageIndex, pageSize);
    }, [pageIndex, pageSize]);

    const handleCreateList = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const list: PropertyList = await createList(newListName);
            setIsModalOpen(false);
            setNewListName('');
            fetchPropertyLists(pageIndex, pageSize);
        } catch (error) {
            console.error('Error creating property list:', error);
        }
    };

    const handleDeleteList = async () => {
        if (listToDelete) {
            try {
                await deleteList(listToDelete.id);
                setDeleteModalOpen(false);
                setListToDelete(null);
                fetchPropertyLists(pageIndex, pageSize);
            } catch (error) {
                console.error('Error deleting property list:', error);
            }
        }
    };

    return (
        <DefaultLayout>
            <Breadcrumb/>
            <PageTitle title='Your Property Lists'/>

            <div className="flex flex-col gap-10">
                <div className="text-right">
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="inline-flex items-center justify-center gap-2.5 rounded-full bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                    >
                        <CirclePlus/>
                        Create Property List
                    </button>
                </div>

                <div
                    className="data-table-common data-table-two rounded-sm border border-stroke bg-white py-4 shadow-default dark:border-strokedark  dark:bg-boxdark">
                    <div className="max-w-full overflow-x-auto">
                        <div className="flex justify-between border-b border-stroke px-8 pb-4 dark:border-strokedark">
                            <div className="w-full sm:w-1/2">
                                <input
                                    type="text"
                                    value={globalFilter || ''}
                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                    className="w-full rounded-md border border-stroke px-5 py-2.5 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
                                    placeholder="PropertiesSearch..."
                                />
                            </div>

                            {pageCount > 1 && (
                                <div className="flex items-center">
                                    <select
                                        value={pageSize}
                                        onChange={(e) => setPageSize(Number(e.target.value))}
                                        className="bg-transparent"
                                    >
                                        {[10, 20, 30, 40, 50].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>

                        <table {...getTableProps()}
                               className="datatable-table w-full table-auto border-collapse overflow-hidden break-words px-4 md:table-fixed md:overflow-auto md:px-8">
                            <colgroup>
                                {columns.map((column) => (
                                    <col key={column.accessor as string} style={{width: column.width}}/>
                                ))}
                            </colgroup>
                            <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className="bg-gray-2 text-left dark:bg-meta-4"
                                        >
                                            {column.render('Header')}
                                            <span>
                                                    {
                                                        column.isSorted ? (
                                                            column.isSortedDesc
                                                                ?
                                                                <ArrowDownWideNarrow size={18} className='inline ml-2'/>
                                                                : <ArrowUpNarrowWide size={18} className='inline ml-2'/>
                                                        ) : <ArrowUpDown size={18} className='inline ml-2'/>
                                                    }
                                                </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} className='cursor-pointer'>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}
                                                    className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark">
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>

                        {pageCount > 1 && (
                            <div
                                className="flex flex-col md:flex-row justify-between items-center border-t border-stroke px-8 py-5 dark:border-strokedark">
                                <p className="font-medium mb-4 md:mb-0">
                                    Showing {pageIndex + 1} of {pageCount} pages
                                </p>
                                <div className="flex items-center">
                                    <button
                                        className="flex cursor-pointer items-center justify-center rounded-md p-1 px-2 hover:bg-primary hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    >
                                        <ChevronsLeft size={18}/>
                                    </button>
                                    <button
                                        className="flex cursor-pointer items-center justify-center rounded-md p-1 px-2 hover:bg-primary hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    >
                                        <ChevronLeft size={18}/>
                                    </button>

                                    {pageOptions.map((page, index) => {
                                        if (
                                            index === 0 ||
                                            index === pageCount - 1 ||
                                            (index >= pageIndex - 1 && index <= pageIndex + 1)
                                        ) {
                                            return (
                                                <button
                                                    key={index}
                                                    onClick={() => gotoPage(index)}
                                                    className={`mx-1 flex cursor-pointer items-center justify-center rounded-md p-1 px-3 hover:bg-primary hover:text-white ${pageIndex === index ? 'bg-primary text-white' : ''
                                                    }`}
                                                >
                                                    {index + 1}
                                                </button>
                                            );
                                        } else if (
                                            index === pageIndex - 2 ||
                                            index === pageIndex + 2
                                        ) {
                                            return <span key={index}>...</span>;
                                        }
                                        return null;
                                    })}

                                    <button
                                        className="flex cursor-pointer items-center justify-center rounded-md p-1 px-2 hover:bg-primary hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    >
                                        <ChevronRight size={18}/>
                                    </button>
                                    <button
                                        className="flex cursor-pointer items-center justify-center rounded-md p-1 px-2 hover:bg-primary hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    >
                                        <ChevronsRight size={18}/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ModalOne isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="Create New Property List">
                <form onSubmit={handleCreateList} className="mt-8">
                    <div className="mb-5.5">
                        <label
                            htmlFor="listName"
                            className="mb-3 block text-sm font-medium text-black dark:text-white"
                        >
                            What's the name of your list?
                        </label>
                        <input
                            type="text"
                            id="listName"
                            placeholder="Enter list name"
                            value={newListName}
                            onChange={(e) => setNewListName(e.target.value)}
                            className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        />
                    </div>

                    <div className="-mx-3 flex flex-wrap gap-y-4">
                        <div className="2xsm:w-1/2 w-full px-3">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1">
                                Cancel
                            </button>
                        </div>
                        <div className="2xsm:w-1/2 w-full px-3">
                            <button type="submit"
                                    className="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90">
                                Create
                            </button>
                        </div>
                    </div>
                </form>
            </ModalOne>

            <DeleteConfirmModal
                isOpen={deleteModalOpen}
                onClose={() => {
                    setDeleteModalOpen(false);
                    setListToDelete(null);
                }}
                onConfirm={handleDeleteList}
                title="Delete Property List"
                message="Are you sure you want to delete this property list?"
            />
        </DefaultLayout>
    );
};

export default PropertyLists;